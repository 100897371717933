button {
	background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    user-select:none;
	cursor:pointer;


	&:focus {
		outline:none;
	}
}

.button-row {
	display:flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin:1.625em -.375em;
	user-select:none;
	
	> * {margin: .375em;}
	
	&.center {justify-content: center;}
	
	.r-align, &.text-right > * {
		@include media(">=sm") {
			margin-left:auto;
		}
	}
}

.log-out-btn{
	@include btn(transparent, #337AED);
	width: 100%;
}

.ant-btn {
	line-height: normal;
}

